export default {
	videos: [
		'video/*',
		'video/quicktime',
		'video/x-quicktime',
		'image/mov',
		'video/avi',
		'video/mp4',
		'application/mp4',
		'application/x-troff-msvideo',
		'video/avi',
		'video/msvideo',
		'video/x-msvideo',
		'video/ogg',
		'video/x-ms-wmv',
		'video/x-ms-asf',
		'video/webm',
	],
	audios: [
		'audio/*',
		'audio/mpeg3',
		'audio/x-mpeg-3',
		'audio/mpeg',
		'video/mpeg',
		'video/x-mpeg',
		'audio/wav',
		'audio/x-wav',
		'audio/x-m4a',
	],
	images: [
		'image/*',
		'image/jpeg',
		'image/pjpeg',
		'image/png',
		'image/avif',
		'image/webp',
	],
	docs: [
		'application/*',
		'application/pdf',
		'application/mspowerpoint',
		'application/powerpoint',
		'application/vnd.ms-powerpoint',
		'application/x-mspowerpoint',
		'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		'application/excel',
		'application/vnd.ms-excel',
		'application/x-excel',
		'application/x-msexcel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'application/doc',
		'application/ms-doc',
		'application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	]
}

