<template>
	<div>
		<v-app-bar fixed color="background" elevation="0" class="ios-bar py-lg-2 pr-lg-3">
			<v-btn icon rounded exact @click="$router.go(-1)">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
			<v-spacer></v-spacer>
			<v-btn @click="save" color="accent" rounded :loading="saving">save</v-btn>
		</v-app-bar>
		<v-container>
			<v-form ref="form" v-model="valid">

				<!--MEMORY DETAILS-->
				<v-card color="transparent" elevation="0">
					<v-card-text>
						<v-row>
							<v-col cols="12" md="6" lg="8" order-md="2">
								<v-row>
									<v-col cols="12">
										<v-text-field label="title" v-model="memory.title" hint="how will you remember this moment" persistent-hint :rules="rules.required"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-textarea label="What's this memory about?" hint="perhaps describe what you were doing, who you were with or how you felt." persistent-hint v-model="memory.description"></v-textarea>
									</v-col>
									<v-col cols="12">
										<v-menu v-model="dateMenu" :close-on-content-click="false" :nudge-left="menuNudgeLeft" transition="scale-transition" offset-y min-width="auto">
											<template v-slot:activator="{ on, attrs }">
												<v-text-field v-model="prettyDate" label="date" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hint="When did this event occur" persistent-hint persistent-placeholder></v-text-field>
											</template>
											<v-date-picker v-model="memory.date" :min="user.date_of_birth" :max="getMaxEndDate" @input="dateMenu = false" :width="datePickerWidth"></v-date-picker>
										</v-menu>
									</v-col>
									<v-col cols="12">
										<v-select
											v-model="memory.topics_attributes"
											:items="topics"
											:rules="rules.topic"
											color="secondary"
											:menu-props="{ maxHeight: '400' }"
											item-text="name"
											item-value="id"
											label="categories"
											multiple
											hint="group with other similar memories"
											persistent-hint
										></v-select>
									</v-col>
								</v-row>
							</v-col>

							<v-col cols="12" md="6" lg="4" order-md="1">
								<v-row>
									<v-col cols="12" class="text-center text-sm-start">
										<v-img :src="getFeaturedImage" class="mb-5 align-content-end" style="max-width: 500px;"></v-img>
										<v-checkbox v-if="getFeaturedImage" v-model="memory.pin_to_year" label="use as year thumbnail" class="mt-0"></v-checkbox>
									</v-col>
									<v-col cols="12" class="text-center text-sm-start">
										<r-file-upload v-model="featured_image" label="featured image" icon="mdi-image"></r-file-upload>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>

				<!--ATTACHMENTS BUTTONS-->
				<v-card color="background" elevation="0">
					<v-card-text>
						<v-row>
							<v-col cols="12" class=" mt-15 text-center">
								<h5 class="text-h5">Web links</h5>
								<p>You can store any links that are related to this memory, such as a youtube video, or news article.</p>
							</v-col>
							<v-col cols="12">
								<v-combobox v-model="memory.urls" label="web links" multiple dense chips hint="Press tab to complete, avoid using commas.">
									<template #selection="{ item }">
										<v-chip color="accent" class="my-1">{{ item }}</v-chip>
									</template>
								</v-combobox>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="12" class="mt-15">
								<h5 class="text-h5 text-center ">voice recording</h5>
							</v-col>
							<v-col cols="auto">
								<r-audio-recorder v-model="audioFiles"></r-audio-recorder>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" class="mt-15">
								<h5 class="text-h5 text-center ">attachments</h5>
								<v-row justify="center" class="my-4">
									<v-col cols="auto">
										<r-file-upload multiple v-model="files" label="upload" icon="mdi-upload"></r-file-upload>
									</v-col>
									<v-col cols="auto">
										<r-camera-upload2 v-model="cameraImages" ref="cameraUpload"></r-camera-upload2>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>


				<v-card color="background" elevation="0">
					<v-card-text>
						<v-row>
							<v-col cols="12" sm="6" md="4" lg="3" v-for="(image, i) in cameraImages">
								<r-card-image :url="getImagePreview(image.blob)" :thumb_url="getImagePreview(image.blob)" editable @click="deleteImage(image, i)"/>
							</v-col>

							<v-col v-for="(file,i) in intentFiles" cols="12" sm="6" md="4" lg="3">
								<r-card-image v-if="getFileType(file) === 'image'" :url="getImagePreview(file.blob)" :thumb_url="getImagePreview(file.blob)" editable @delete="deleteIntent(file, i)"/>
								<r-card-video v-if="getFileType(file) === 'video'" :url="getImagePreview(file.blob)" editable @delete="deleteIntent(file, i)"/>
								<r-card-audio v-if="getFileType(file) === 'audio'" :url="getImagePreview(file.blob)" editable @delete="deleteIntent(file, i)"/>
								<r-card-doc v-if="getFileType(file) === 'doc'" :url="getImagePreview(file.blob)" :file="{file}" editable @delete="deleteIntent(file, i)"/>
							</v-col>

							<v-col v-for="(file,i) in files" cols="12" sm="6" md="4" lg="3">
								<r-card-image v-if="getFileType(file) === 'image'" :url="getImagePreview(file)" :thumb_url="getImagePreview(file)" editable @delete="deleteFile(file, i)"/>
								<r-card-video v-if="getFileType(file) === 'video'" :url="getImagePreview(file)" editable @delete="deleteFile(file, i)"/>
								<r-card-audio v-if="getFileType(file) === 'audio'" :url="getImagePreview(file)" editable @delete="deleteFile(file, i)"/>
								<r-card-doc v-if="getFileType(file) === 'doc'" :url="getImagePreview(file)" :file="{file}" editable @delete="deleteFile(file, i)"/>
							</v-col>

							<v-col v-for="(file,i) in audioFiles" cols="12" sm="6" md="4" lg="3">
								<r-card-audio :url="getImagePreview(file)" editable @delete="deleteAudioFile(file, i)"/>
							</v-col>

							<template v-if="isEdit">
								<v-col v-for="attachment in memory.attachments_attributes" cols="12" sm="6" md="4" lg="3">
									<template v-if="!attachment._destroy">
										<r-card-image v-if="attachment.type === 'image'" :url="attachment.url" :thumb_url="attachment.thumb_url" editable @delete="deleteAttachment(attachment)"/>
										<r-card-video v-if="attachment.type === 'video'" :url="attachment.url" editable @delete="deleteAttachment(attachment)"/>
										<r-card-audio v-if="attachment.type === 'audio'" :url="attachment.url" editable @delete="deleteAttachment(attachment)"/>
										<r-card-doc v-if="attachment.type === 'doc'" :url="attachment.url" :filename="attachment.meta.filename" editable @delete="deleteAttachment(attachment)"/>
									</template>
								</v-col>
							</template>
						</v-row>
					</v-card-text>
				</v-card>
			</v-form>
		</v-container>
	</div>
</template>

<script>

import mime_types from '@/utils/mime_types'
import _findKey from 'lodash/findKey'
import {sync} from "vuex-pathify";
import {Encoding, Filesystem, FilesystemDirectory} from "@capacitor/filesystem";
import {b64toBlob, convertBlobToBase64} from "@/utils/helpers";

export default {
	name: "MemoryForm",
	props: ['memory', 'id', 'year', 'intent'],
	computed: {
		isEdit() {
			return this.$route.meta.edit
		},
		getFeaturedImage() {
			return this.featured_image == null ? this.memory.featured_image_attributes : this.getImagePreview(this.featured_image)
		},
		topics: sync('app/topics'),
		user: sync('user/user'),
		prettyDate() {
			return this.memory.date ? new Date(this.memory.date).toLocaleDateString('en-GB') : new Date().toLocaleDateString('en-GB')
		}
	},
	async mounted() {
		this.formData = new FormData();
		if (this.intent) {
			const {data} = await Filesystem.readFile({
				path: this.intent.url,
			});
			this.intentFiles.push({
				type: this.intent.type,
				blob: b64toBlob(data, this.intent.type)
			})
		}
	},
	data: () => ({
		valid: false,
		rules: {
			required: [
				v => !!v || 'Required',
			],
			topic: [
				v => v.length > 0 || 'You must select a category'
			]
		},
		dateMenu: false,
		featured_image: null,
		cameraImages: [],
		audioFiles: [],
		intentFiles: [],
		files: [],
		formData: null,
		saving: false
	}),
	methods: {
		deleteImage(file, index = null) {
			if (index !== null) {
				this.cameraImages.splice(index, 1)
			}
		},

		getFileType(file) {
			if (mime_types.images.includes(file.type)) return 'image'
			if (mime_types.videos.includes(file.type)) return 'video'
			if (mime_types.docs.includes(file.type)) return 'doc'
			if (mime_types.audios.includes(file.type)) return 'audio'
			return 'unknown'
		},

		save() {
			if (this.$refs.form.validate()) {
				this.saving = true;
				this.formData.append("memory[title]", this.memory.title);
				this.formData.append("memory[description]", this.memory.description);
				this.formData.append("memory[date]", this.memory.date);
				this.formData.append("memory[pin_to_year]", this.memory.pin_to_year);
				this.memory.urls.forEach(i => this.formData.append("memory[urls][]", i))
				this.memory.topics_attributes.forEach(i => this.formData.append("memory[topics_attributes][][topic_id]", i))

				if (this.isEdit) {
					this.memory.attachments_attributes.filter(att => att._destroy).forEach(a => {
						this.formData.append(`memory[attachments_attributes][][id]`, a.id);
						this.formData.append(`memory[attachments_attributes][][_destroy]`, true);
					})
				}

				this.audioFiles.forEach(i => this.formData.append("memory[attachments_attributes][][file]", i))
				this.cameraImages.forEach(i => this.formData.append("memory[attachments_attributes][][file]", i.blob))
				this.files.forEach(x => this.formData.append("memory[attachments_attributes][][file]", x))
				if (this.featured_image) {
					this.formData.append("memory[featured_image_attributes][file]", this.featured_image)
				}
				if (this.isEdit) {
					this.$api.memories.update(this.id, this.formData)
						.then(response => {
							this.$router.push({name: 'ShowMemory', params: {id: this.id}})
							this.cameraImages = []
						})
						.catch(error => {
							this.error = error
							console.log(error)
						})
						.finally(() => {
							this.formData = new FormData();
							this.saving = false;
						})
				} else {
					this.$api.memories.create(this.formData)
						.then(response => {
							this.$router.push({name: 'ShowMemory', params: {id: response.data.id}})
							this.cameraImages = []
						})
						.catch(error => {
							this.error = error
							console.log(error)
						})
						.finally(() => {
							this.formData = new FormData();
							this.saving = false;
						})
				}
			}
		},
		deleteFile(file, i) {
			this.files.splice(i, 1)
		},
		deleteIntent(file, i) {
			this.intentFiles.splice(i, 1)
		},
		deleteAudioFile(file, i) {
			this.audioFiles.splice(i, 1)
		},
		deleteAttachment(attachment) {
			let key = _findKey(this.memory.attachments_attributes, (o) => o.id === attachment.id)
			this.$set(this.memory.attachments_attributes, key, {...attachment, _destroy: true})
		},
		pinToYear() {
			this.memory.pin_to_year = true
		},
	}
}
</script>